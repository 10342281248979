<template>

    <div>
        <div class="c-form__header">
            <h3>{{ stepConfig.title }}</h3>
        </div>

        <template v-if="stepConfig.step_type != 'slider' && stepConfig.step_type !== 'slider_simple'">
          <div class="c-form__items" :class="{'c-form__items--text' : stepConfig.step_type == 'text', 'c-form__items--image' : stepConfig.step_type == 'image'}">
            <template v-for="(choice, idx) in stepConfig.choices" :key="idx">
              <input :id="'input-' + stepConfig.key + '-' + idx" :type="stepConfig.multiple ? 'checkbox' : 'radio'" :name="stepConfig.key" v-model="val" :value="choice.value" />
              <label :for="'input-' + stepConfig.key + '-' + idx" class="c-form__item" v-on:click="checkItem" >
                <div class="c-form__item__check">
                  <svg viewBox="0 0 24 24">
                    <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                  </svg>
                </div>

                <div class="c-form__item__img" v-if="choice.icon && !choice.href">
                  <img :src="choice.icon" :alt="choice.text" />
                </div>
                <div class="c-form__item__img" v-if="choice.icon && choice.href">
                  <a @click="redirect = true" :href="choice.href" rel="noopener noreferrer"><img :src="choice.icon" :alt="choice.text" /></a>
                </div>
                <div class="c-form__item__text">

                  {{choice.text}}
                </div>
              </label>
            </template>

          </div>

          <div class="c-form__footer">
            <button type="button" class="btn btn--notice" v-on:click="nextStep" :style="{visibility: stepConfig.multiple ? 'visible' : 'hidden' }">Weiter ></button>
          </div>
        </template>
        <template v-if="stepConfig.step_type === 'slider_simple'">
            <div class="slider-simple-component">
              <div class="sc__main__simple">
                <div :class="{'text--bg-is-dark': config.bg_isDark}" class="sc__main_slider__simple__value" >
                  {{renderValue(val)}}
                </div>
                <Slider v-if="!Array.isArray(val)" v-model="stepConfig.value" :min="stepConfig.min - stepConfig.step" @update="changeInputVal" :max="stepConfig.max + stepConfig.step" :step="stepConfig.step"  :format="format" :tooltips="false" />
                <div class="sc__main__labels__simple">
                  <div :class="{'text--bg-is-dark': config.bg_isDark}">{{renderValue(stepConfig.min - stepConfig.step)}}</div>
                  <div></div>
                  <div :class="{'text--bg-is-dark': config.bg_isDark}">{{renderValue(stepConfig.max + stepConfig.step)}}</div>
                </div>
                <div style="flex-grow: 1">

                </div>
                <div class="sc__main__row__simple">
                  <div class="sc__main__row__button__simple">
                    <button type="button" class="btn btn--notice" v-on:click="nextStep" >Weiter ></button>
                  </div>
                </div>
              </div>
            </div>
        </template>
        <template v-if="stepConfig.step_type === 'slider'">
            <div class="slider-component">
              <div class="sc__image"  v-if="stepConfig.icon">
                <img :src="stepConfig.icon" />
              </div>
              <div class="sc__main">
                <Slider v-if="!Array.isArray(val)" v-model="val" :min="stepConfig.min" @update="changeInputVal" :max="stepConfig.max" :step="stepConfig.step"  :format="format" />
                <div class="sc__main__labels">
                  <div>{{renderValue(stepConfig.min - stepConfig.step)}}</div>
                  <div></div>
                  <div>{{renderValue(stepConfig.max + stepConfig.step)}}</div>
                </div>
                <div style="flex-grow: 1">

                </div>
                <div class="sc__main__row">
                  <div class="sc__main__row__text">Alternativ <br/>genaue Angabe</div>
                  <div>
                    <input class="sc__main__input" type="text" v-model="val" /> {{ stepConfig.append_value }}
                  </div>
                  <div class="sc__main__row__button">
                    <button type="button" class="btn btn--notice" v-on:click="nextStep" >Weiter ></button>

                  </div>
                </div>
              </div>
            </div>
        </template>

    </div>
    
</template>
<style scoped>



.slider-component {
  display: flex;
  max-width: 800px;
  margin: auto;
  --slider-tooltip-bg: v-bind('config.baseColor');;
  --slider-connect-bg: v-bind('config.baseColor');;
  --slider-height: 20px;
  --slider-handle-width: 30px;
  --slider-handle-height: 30px;
  background: white;
  padding: 30px 15px;
}

.slider-simple-component {
  display: flex;
  max-width: 800px;
  margin: auto;
  --slider-tooltip-bg: v-bind('config.baseColor');;
  --slider-connect-bg: v-bind('config.baseColor');;
  --slider-height: 20px;
  --slider-handle-width: 30px;
  --slider-handle-height: 30px;
  padding: 30px 15px;
}

.sc__image {
  width: 200px;
}

.sc__main {
  padding-top: 40px;
  padding-left: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  min-width: 500px;
  max-width: 100%;
}

.sc__main__input {
  width: 100px;
  padding: 5px;
  margin: 6px;
  border-radius: 5px;
  border: none;
  background: #C7CFD9;
}

.sc__main__row,
.sc__main__labels {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.sc__image__simple {
  width: 200px;
}

.sc__main__simple {
  padding-top: 40px;
  padding-left: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.sc__main__input__simple {
  width: 100px;
  padding: 5px;
  margin: 6px;
  border-radius: 5px;
  border: none;
  background: #C7CFD9;
}

.sc__main__labels__simple {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc__main__row__simple {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc__main_slider__simple__value{
  font-size: 20px;
  display: block;
  text-align: center;
  line-height: 1.1;
  padding: 15px 0 42px;
  font-weight: bold;
}

.text--bg-is-dark {
  color: white;
  text-shadow: 0px 0px 15px #000000b3;
}


.c-form__item__check {
  position: absolute;
  right: .3em;
  top: .3em;
  font-size: 20px;
  opacity: 0;
  transition: opacity .4s ease-in-out;
  z-index: 1;
}
.c-form__item__check svg {
  width: 1em;
  height: 1em;
  background: var(--header-notice-bg-color);
  color: white;
  border-radius: 50%;
  padding: .15em;
}
.c-form__footer button {

}
.c-form__footer {
  text-align: center;
  margin-top: 20px;
}

.c-form__item__img {

}
.c-form__item__img img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  height: calc(100% - 2.5em);
  width: 100%;
  object-fit: contain;
  padding: 20px;
  box-sizing: border-box;
}

.c-form__item {
  position: relative;
  width: 200px;
  background: white;
  margin: 1%;
  font-size: 16px;
  border-radius: var(--default-border-radius);
  border: 4px solid transparent;
  transition: all .3s ease-in-out;
  cursor: pointer;
}


input:checked + .c-form__item .c-form__item__check {
  opacity: 1;
}
input:checked + .c-form__item,
.c-form__item:HOVER {
  border-color: var(--header-notice-bg-color);
  color: var(--header-notice-bg-color);
}

.c-form__items input {
  display: none;
}

.c-form__item__text {
  text-align: center;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100%;

}

.c-form__items--text {
  flex-direction: column;
  align-items: center;
}

.c-form__items--text .c-form__item {
  font-size: 21px;
  min-height: 50px;
  padding: 16px 0;
  width: 100%;
  max-width: 320px;
  margin: 5px 0;
  border: none;
  background: v-bind('config.baseColor');
  background-image: -webkit-linear-gradient(bottom, v-bind('config.baseColor') 0%,  v-bind('config.baseColorLight') 100%);
  color: v-bind('config.baseColorText');
  font-weight: bold;
}
.c-form__items--text .c-form__item__text {
  padding: 0;
}

.c-form__items--text input:checked + .c-form__item {
  color: v-bind('config.baseColorText');
}

.c-form__items {
  display: flex;
  justify-content: center;
}



@media (max-width: 800px) {
  .sc__image {
    flex-shrink: 1;
    width: auto;
  }
  .sc__image img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 700px) {
  .c-form__items {
    flex-wrap: wrap;
  }

  .c-form__item {
    width: 40%;
    max-width: 200px;
  }
}


@media (max-width: 680px) {
  .sc__image {
    display: none;
  }
  .sc__main {
    padding: 30px 15px;
  }
}

@media (max-width: 680px) {
  .sc__main__row {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
  }

  .sc__main__row > div {
    min-width: 40%;
    flex-grow: 1;
  }

  .sc__main__row__button {
    margin-top: 20px;
  }

  .sc__main__row__button__simple {
    margin-top: 20px;
  }

  .sc__main__row__button button {
    width: 100%;
  }

  .sc__main__row__button__simple button {
    width: 100%;
  }

  .sc__main__row__text {
    font-size: .8em;
  }


  .sc__main {
    padding-bottom: 0;
  }
}

@media (max-width: 680px) {
  .sc__main {
    min-width: auto;
  }
}


@media (max-width: 450px) {

  .c-form__item {
    width: 45%;
    max-width: 150px;
  }

  .c-form__item__text {
    font-size: 18px;
  }
}

</style>

<script>
    import Slider from '@vueform/slider'
    import Tracking from "../../helpers/Tracking.js";

    export default {
        name: "ImageStep",
        props: ['config', 'stepConfig', 'isActive'],
        emits: ['nextStep', 'update'],
        components: {
          Slider
        },
        data: ()=>{
          return {
            val: [],
            valSlider: 300,
            redirect: false,
          }
        },
        watch: {
          stepConfig(){

          },
          isActive(newVal) {
            if (newVal) {
              Tracking.sendPageView(this.config, this.stepConfig);

              if(this.stepConfig.step_type == 'slider' || this.stepConfig.step_type == 'slider_simple'){
                this.changeInputVal(this.stepConfig.value);
                console.log("PG:",this.stepConfig.value)
                console.log("PG:",this.stepConfig)
                console.log("PG:",this.isActive)
                //this.valSlider = this.stepConfig.value;
                //this.changeInputVal(this.valSlider);
              } else {
                this.val = [];
              }
            }
          },
        },
        created() {
          if(this.stepConfig.step_type == 'slider' || this.stepConfig.step_type == 'slider_simple'){
            this.changeInputVal(this.stepConfig.value);
            //this.valSlider = this.stepConfig.value;
            //this.changeInputVal(this.valSlider);
          }
        },
      methods : {
        changeInputVal(val){
          this.val = val;
        },

        format(val){
            return this.renderValue(val);
        },
        renderValue(val){
          if(val < this.stepConfig.min){
            return '< ' + (val + this.stepConfig.step) + ' ' + this.stepConfig.append_value;
          }

          if(val > this.stepConfig.max){
            return '> ' + (val - this.stepConfig.step) + ' ' + this.stepConfig.append_value;
          }

          return Math.round(val) + ' ' + this.stepConfig.append_value;
        },
          checkItem(){
            if(this.stepConfig.multiple) return;

            window.setTimeout(()=>{
              this.nextStep();
            }, 300);
          },
          nextStep(){
            if(this.redirect){
              return;
            }

            let val = {};
            val[this.stepConfig.key] = this.val;



            this.$emit('update', val);
            this.$emit('nextStep', val);

            if(window['dataLayer']){
              let dataLayerData = { 'event': 'select-value'};
              dataLayerData[this.stepConfig.key] = this.val;
              window.dataLayer.push(dataLayerData);
            }
            if(window['gtag']){
              window.gtag('event', 'select-value', {
                'event_category': this.stepConfig.key,
                'event_label': this.val,
                'value': this.val
                });
            }
          }
        }
    }
</script>
