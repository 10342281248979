<template>
    <div class="stepper">
      <div class="header-notice" v-if="stepConfig.head_notice">
        {{ stepConfig.head_notice }}
      </div>

      <div class="c-form__titles" v-if="stepConfig.title || stepConfig.subtitle">
        <h1 v-html="stepConfig.title" class="text-on-bg-image"></h1>
        <h2 class="text-on-bg-image">{{ stepConfig.subtitle }}</h2>
      </div>
      <div class="stepper-inner" ref="scrollView">
        <template v-for="(step, idx) in steps" :key="idx">
          <transition name="step-slide" @enter="enter" @leave="leave">
            <component :is="step.component ? step.component : 'ImageStep'" :data="data" :config="config" :step-config="step" @update="update" @next-step="nextStep" :is-active="idx == currentStepIdx" v-show="idx == currentStepIdx" />
          </transition>
        </template>
      </div>


    </div>
</template>

<script>
    import ImageStep from './steps/ImageStep.vue'
    import InfoStep from './steps/InfoStep.vue'
    import ZipStep from './steps/ZipStep.vue'
    import SearchStep from './steps/SearchStep.vue'
    import DataStep from './steps/DataStep.vue'
    import AfterSave from './steps/AfterSave.vue'
    import MapStep from './steps/MapStep.vue';
    import TextInputStep from './steps/TextInputStep.vue';

    export default {
        name: "Stepper",
        emits: ['nextStep', 'update', 'isActive'],
        components: {
            SearchStep,
            DataStep,
            ImageStep,
            InfoStep,
            ZipStep,
            AfterSave,
            MapStep,
            TextInputStep
        },
        mounted() {
          this.filterSteps();
        },
        watch: {
          /*data: {
            handler(){
              this.filterSteps();
            },
            deep: true
          },*/
          isActive(newVal){
            if(newVal){
              //this.sendPageView();
            }
          }
        },
        methods: {
          filterSteps(){
            let steps = this.stepConfig.steps;
            let data = this.data;
            let newSteps = [];
            this.stepConfig.steps.forEach((step) => {
              if(step['condition'] && step['condition']['value'] && step['condition']['key']){
                if(this.data && this.data[step['condition']['key']] ==  step['condition']['value']){
                  newSteps.push(step);
                }
              } else {
                newSteps.push(step);
              }
            });


            this.steps = newSteps;
          },

          update(val){
              this.$emit('update', val);
          },
          nextStep(val){
            this.filterSteps();
            if(this.lockStep) return;




            let match = window.matchMedia('(max-width: 600px)');
            if(this.$refs.scrollView && match && match.matches){
              this.$refs.scrollView.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }

            this.lockStep = true;
            window.setTimeout(()=>{
              this.lockStep = false;
            }, 500);
            this.currentStepIdx = this.currentStepIdx +1;
            if(this.currentStepIdx >= this.steps.length){
              this.$emit('nextStep', val);
            } else {
              //this.sendPageView();
            }
          },
          enter(element) {
            const gcs = getComputedStyle(element);
            //const width = gcs.width;
            const height = gcs.height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            //getComputedStyle(element).height;

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            setTimeout(()=>{
              requestAnimationFrame(() => {
                element.parentNode.style.height = height;
              });
            }, 50);
          },
          leave(element) {

            const gcs = getComputedStyle(element);
            //const width = gcs.width;
            const height = gcs.height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            //getComputedStyle(element).height;

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
              element.parentNode.style.height = height;
              getComputedStyle(element)
            });
          },
        },
        props: ['config', 'stepConfig', 'data'],
        data: function(){
          return {
            currentStepIdx: 0,
            lockStep:  false,
            steps: []
          };
        }
    }
</script>
<style>
.c-form {
  overflow-x: hidden;
}
</style>
<style scoped>

.stepper-inner {
  position: relative;
  transition: height .5s ease-in-out;

}
.header-notice {

  top: -50px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
h1 {
  margin-top: 0;
}
.step-slide-enter-active {
  position: absolute;
  /* top: 0; */
  left: 0;
  right: 0;
  top: 0;
  transition: all .5s ease-in-out;
}

.step-slide-leave-active {
  transition: all .5s ease-in-out;
}

.step-slide-enter-from {
  opacity: 0;
  transform: translateX(100%);
}
.step-slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}


@media (max-width: 750px) {
  .c-form__titles {
    margin-top: -35px;
  }
  .c-form__titles h3 {
    font-size: 16px;
  }
  .c-form__titles h1 {
      font-size: 20px;
      margin-bottom: 10px;
  }

  .c-form__titles h2 {
      font-size: 17px;
      margin-bottom: 0;
    margin-top: 0;
  }
}
</style>
