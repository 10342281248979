<template>
  <div>
    <div class="card">
      <h3 v-if="stepConfig.title">{{ stepConfig.title }}</h3>
      <p v-if="stepConfig.text" style="text-align: center; font-weight: bold;">
        {{stepConfig.text}}
      </p>
      <div class="input-card">
        <input
            type="text"
            class="full-size input"
            required="required"
            v-model="val"
            :placeholder="stepConfig.placeholder ?? ''"
            ref="inputField"
        />
      </div>
      <div v-if="stepConfig.button" class="card__button">
        <EzaButton margin-bottom="0" :text="stepConfig.button.text" @click="nextStep" :href="null"></EzaButton>
      </div>
    </div>
  </div>
</template>

<script>
import Tracking from "../../helpers/Tracking.js";
import EzaButton from "../form/EzaButton.vue";

export default {
  name: "TextInputStep",
  components: {EzaButton},
  props: ["isActive", "config", "stepConfig", "data"],
  emits: ["nextStep"],
  watch: {
    isActive(newVal) {
      if (newVal) {
        Tracking.sendPageView(this.config, this.stepConfig);
      }
    }
  },
  data: () => {
    return {
      val: null
    };
  },
  methods: {
    nextStep() {
      // Check if the input field is valid
      if (!this.$refs.inputField.checkValidity()) {
        // If invalid, show the validation message
        this.$refs.inputField.reportValidity();
        return;
      }

      if (this.redirect) {
        return;
      }

      let val = {};
      val[this.stepConfig.key] = this.val;

      this.$emit('update', val);
      this.$emit('nextStep', val);

      if (window['dataLayer']) {
        let dataLayerData = { 'event': 'select-value' };
        dataLayerData[this.stepConfig.key] = this.val;
        window.dataLayer.push(dataLayerData);
      }
      if (window['gtag']) {
        window.gtag('event', 'select-value', {
          'event_category': this.stepConfig.key,
          'event_label': this.val,
          'value': this.val
        });
      }
    }
  },
}
</script>

<style scoped>
h3 {
  margin-top: 0;
}

h4 {
  text-align: center;
}

.input-card{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.input{
  width: 60%;
}

.card {
  width: 800px;
  max-width: 100%;
  box-sizing: border-box;
}

.card h3 {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

.card__row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.card__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

p {
  line-height: 1.5em;
}
</style>
