<template>

  <div>
    <div class="card">
      <h3>{{ stepConfig.title ? stepConfig.title : 'Bitte haben Sie einen Augenblick Geduld.'}}</h3>
      <h4>{{ stepConfig.subtitle ? stepConfig.subtitle : 'Unser System sucht jetzt aus unseren Anbietern für Sie!'}}</h4>
      <div class="card__row">
        <div class="sk-circle"><div class="sk-circle1 sk-child"></div><div class="sk-circle2 sk-child"></div><div class="sk-circle3 sk-child"></div><div class="sk-circle4 sk-child"></div><div class="sk-circle5 sk-child"></div><div class="sk-circle6 sk-child"></div><div class="sk-circle7 sk-child"></div><div class="sk-circle8 sk-child"></div><div class="sk-circle9 sk-child"></div><div class="sk-circle10 sk-child"></div><div class="sk-circle11 sk-child"></div><div class="sk-circle12 sk-child"></div></div>
      </div>
    </div>
  </div>

</template>

<script>
import Tracking from "../../helpers/Tracking.js";

export default {
  name: "SearchStep",
  props: ['isActive', 'config', 'stepConfig', 'data'],
  emits: ['nextStep'],
  watch: {
    isActive(newVal){
      if (newVal) {
        Tracking.sendPageView(this.config, this.stepConfig);
      }
      if(newVal){
        const params = new URLSearchParams();
        params.append('zip', this?.data?.contact_zip);
        params.append('lead_type', this?.config?.type);

        if(!this?.config?.enable_direct_partner){
          window.setTimeout(()=>{
            this.$emit('nextStep');
          }, 2000);
          return;
        }

        try{
          fetch('https://einfach-zum-angebot.de/check-zip.php?'+params.toString(), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(data => {
            if(data?.real_price !== undefined && this.stepConfig?.price_threshold !== undefined){
              if(data.real_price <= this.stepConfig.price_threshold){
                window.direct_partner = true;
                window.setTimeout(()=>{
                  this.$emit('nextStep');
                }, 2000);
                return true;
              }
            }
            window.setTimeout(()=>{
              this.$emit('nextStep');
            }, 2000);
            window.direct_partner = false;
          });
        } catch(e){
          window.direct_partner = false;
          window.setTimeout(()=>{
            this.$emit('nextStep');
          }, 2000);
          console.log(e);
        }

      }
    }
  },
  data: ()=>{
    return {
      val: null
    }
  },
  methods : {

  }
}
</script>

<style scoped>

h3 {
  margin-top: 0;
}

h4 {
  text-align: center;
}

.card {
  width: 800px;
  max-width: 100%;
  box-sizing: border-box;
}

.card h3 {
  font-size: 25px;
  font-weight: normal;
  text-align: center;
}

.card__row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.card__row .sk-circle {
  margin-right: 15px;
}

.sk-circle {
  width: 100px;
  height: 100px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}



.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
.sk-circle .sk-child::before {
  background-color: #FF7800;
}
@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
