


export default class Tracking {


    static sendPageView(config, stepConfig){
        let k = 'nokey';
        if(stepConfig['key']){
            k = stepConfig['key'];

        } else if(stepConfig['component']){
            k = stepConfig['component'];

        }
        let title = 'notitle';
        if(stepConfig){
            title = stepConfig.component ? stepConfig.component : 'ImageStep';
            if(stepConfig.title){
                title += ' - ' + stepConfig.title;

            }

        }

        let append = '/lp/' + config.type.toLowerCase() + '/vergleich/' + k;
        let gtagEvent = {
            page_title: title,
            page_location: window.location.href,
            page_path: append
        };


        if(window['gtag']){
            console.log('gtag event', gtagEvent);
            window['gtag']('event', 'page_view', gtagEvent);
        }

        if(window['ga']){
            console.log('ga event', gtagEvent);
            window['ga']('send', 'pageview', gtagEvent.page_path, {
                title: gtagEvent.page_title
            });
        }


    }

}
