<template>
  <div class="map_component">
    <h3>{{ stepConfig.title }}</h3>
    <div class="row-top">
          <input type="text" :id="'input-' + stepConfig.key + 'map_search'" v-model="search"
                 class="input-def map-search"
                 ref="search" placeholder="z.B. Bahnhofstr. 12,  Gauting">
        </div>
    <form id="map-form" class="flex"
    @submit.prevent="nextStep">
      <div class="input-col">
        <div class="row row-2">
          <input @change="queryGooglePlacesAPI" ref="streetInput" type="text" placeholder="Straße" :id="'input-' + stepConfig.key + 'map_strasse'" v-model.lazy="street"
                 class="input-def map-street" required>
          <input @change="queryGooglePlacesAPI" ref="numberInput" type="text" placeholder="Hausnr." :id="'input-' + stepConfig.key + 'map_hausnr'" v-model.lazy="house"
                 class="input-def map-house" required>
        </div>
        <div class="row row-3">
          <input @change="queryGooglePlacesAPI" ref="plzInput" type="text" placeholder="PLZ" pattern="[0-9]{5}" :id="'input-' + stepConfig.key + 'map_plz'" v-model.lazy="index"
                 class="input-def map-index" required>
          <input @change="queryGooglePlacesAPI" ref="ortInput" type="text" placeholder="Ort" :id="'input-' + stepConfig.key + 'map_ort'" v-model.lazy="location"
                 class="input-def map-location" required>
        </div>
      </div>
      <div class="map_col">
        <div class="map" id="map" ref="map"></div>
      </div>
    </form>
    <div class="button-container">
              <button form="map-form" type="submit" class="btn btn--notice full-size btn-mapstep" >Weiter ></button>
        </div>
  </div>
</template>

<script>
import {loadedGoogleMapsAPI} from "../../main";

export default {
  name: "MapStep",
  props: ['config', 'stepConfig', 'isActive'],
  emits: ['nextStep', 'update'],
  data() {
    return {
      search: null,
      street: null,
      house: null,
      index: null,
      location: null,
      googleMap: null,
      timeout: null,
    }
  },
  methods: {
    initMap() {
      const componentForm = [
        'search',
        'locality',
        'administrative_area_level_1',
        'country',
        'postal_code',
      ];
      this.googleMap = new google.maps.Map(this.$refs.map, {
        zoom: 15,
        center: {lat: 37.4221, lng: -122.0841},
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false
      })
      const marker = new google.maps.Marker({map: this.googleMap, draggable: false});
      const autocompleteInput = this.$refs.search;
      const autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
        fields: ["address_components", "geometry", "name"],
        types: ["address"],
      })
      autocomplete.setComponentRestrictions({
        country: ["de"],
      });
      autocomplete.addListener('place_changed', () => {
            marker.setVisible(false);
            const place = autocomplete.getPlace();
            if (!place.geometry) {
              console.log('No details available for input: \'' + place.name + '\'');
              return;
            }
            this.search = place.name
            const addressNameFormat = {
              'street_number': 'short_name',
              'route': 'long_name',
              'locality': 'long_name',
              'administrative_area_level_1': 'short_name',
              'postal_code': 'short_name',
            };
            const getAddressComp = function (type) {
              for (const component of place.address_components) {
                if (component.types[0] === type) {
                  return component[addressNameFormat[type]];
                }
              }
              return '';
            };
            this.index = getAddressComp('postal_code')
            this.house = getAddressComp('street_number')
            this.location = getAddressComp('locality')
            this.street = getAddressComp('route')
            // this.search = getAddressComp('street_number') + ' ' + getAddressComp('route');
            this.googleMap.setCenter(place.geometry.location);
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);
            this.queryGooglePlacesAPI();
          }
      );
    },
    nextStep(){
      let val = {};
      val['contact_street'] = `${this.street} ${this.house}`;
      val['contact_zip'] = this.index;
      val['contact_city'] = this.location;

      this.$emit('update', val);
      this.$emit('nextStep', val);
    },
    lazyInput(e){
      const input = e.target;
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.queryGooglePlacesAPI();
      }, 2000);
    },
    queryGooglePlacesAPI(){
      //Check if the API is loaded
      if(!loadedGoogleMapsAPI){
        return;
      }
      //Check if all form fields are filled
      if(!this.street || !this.house || !this.index || !this.location){
        return;
      }
      //Query the API
      const query = `${this.street} ${this.house} ${this.index} ${this.location}`;
      const service = new google.maps.places.PlacesService(this.googleMap);
      service.findPlaceFromQuery({fields: ["place_id"],query: query}, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.$refs.streetInput.setCustomValidity("");
          this.$refs.numberInput.setCustomValidity("");
          this.$refs.plzInput.setCustomValidity("");
          this.$refs.ortInput.setCustomValidity("");
          const place_id = results[0].place_id;
          service.getDetails({placeId: place_id, fields: ["address_components", "geometry", "name"]}, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placeDetails = place;
          const addressNameFormat = {
            'street_number': 'short_name',
            'route': 'long_name',
            'locality': 'long_name',
            'administrative_area_level_1': 'short_name',
            'postal_code': 'short_name',
          };
          const getAddressComp = function (type) {
            for (const component of placeDetails.address_components) {
              if (component.types[0] === type) {
                return component[addressNameFormat[type]];
              }
            }
            return '';
          };
          this.index = getAddressComp('postal_code')
          this.house = getAddressComp('street_number')
          this.location = getAddressComp('locality')
          this.street = getAddressComp('route')
          this.googleMap.setCenter(placeDetails.geometry.location);
          const marker = new google.maps.Marker({map: this.googleMap, draggable: false});
          marker.setPosition(placeDetails.geometry.location);
          marker.setVisible(true);
          }
          });
        } else {
          this.$refs.streetInput.setCustomValidity(
                "Addresse wurde nicht gefunden!"
              );
        this.$refs.numberInput.setCustomValidity(
                "Addresse wurde nicht gefunden!"
              );
         this.$refs.plzInput.setCustomValidity(
                "Addresse wurde nicht gefunden!"
              );
         this.$refs.ortInput.setCustomValidity(
                "Addresse wurde nicht gefunden!"
              );
        }
      });
    }
  },
  async mounted() {
    this.$refs.streetInput.setCustomValidity(
                "Bitte Addresse angeben!"
              );
        this.$refs.numberInput.setCustomValidity(
                "Bitte Addresse angeben!"
              );
         this.$refs.plzInput.setCustomValidity(
                "Bitte Addresse angeben!"
              );
         this.$refs.ortInput.setCustomValidity(
                "Bitte Addresse angeben!"
              );
    loadedGoogleMapsAPI.then(() => {
      this.initMap()
    });
  },
}
</script>

<style scoped>
.map_component {
  flex-wrap: wrap;
  display: flex;
  max-width: 800px;
  margin: auto;
  background: white;
  padding: 2rem;
}

h3 {
  margin-top: 0;
  width: 100%;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.flex{
  display: flex;
  width: 100%;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
}

.row-top {
  width: 100%;
  padding-right: 30px;
}

.input-def {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  border: 1px solid #e2e8f0;
  padding: 12px 16px;
}

.input-col {
  border-top: #e4e4e4 solid 2px;
margin-top: 20px;
box-sizing: border-box;
margin-right: 30px;
  width: 100%;
}

.map-search {
  width: 100%;
}

.map-street {
  width: 60%;
}

.map {
  width: 100%;
  height: 250px;
}

.map_col {
  width: 100%;
  margin-top: 20px;
}

.row-2 {
  margin-top: 30px;
}

.map-house {
  margin-left: auto;
  width: 20%;
}

.row-3 {
  margin-top: 20px;
}

.map-index {
  width: 30%;
}

.btn-mapstep {
  width: 100%;
}

.map-location {
  margin-left: auto;
  width: 50%;
}

.button-container {
  margin-top: 20px;
  width: 100%;
}

@media ( min-width: 720px ) {
  .input-col {
  border-top: #e4e4e4 solid 2px;
margin-top: 20px;
box-sizing: border-box;
margin-right: 30px;
  width: 58%;
}
	.row {
  width: 100%;
  display: flex;
}
.row-top {
  width: 52%;
}

.map_col {
  width: 41%;
}

.button-container {
  margin-top: -50px;
}

.btn-mapstep {
  width: 57%;
}

.flex{
  display: flex;
  width: 100%;
  flex-direction: row;
}

}
</style>