<template>
    <div>
        <DebugWindow v-if="config && config.debug === 'ajaska'" :data="data"/>
        <div class="header" >
            <div class="header__inner" :class="{'header--center': !config.head_right}">
              <img class="header__logo" :src="config.logo" alt="Logo" :style="{ transform: config['logo_big'] ? 'scale(1.5)' : '' }" />

              <div class="header__right" v-if="config.head_right">
                <div v-for="(hr, idx) in config.head_right" :key="idx" v-html="hr" >

                </div>
              </div>
            </div>

        </div>

        <div class="c-form" :class="{'c-form--bg-is-dark': config.bg_isDark}">
            <div class="c-form__bg"  :style="{
              backgroundImage: 'url(' + config.bg_image + ')',
              backgroundPosition: config.bg_pos ? config.bg_pos : false,
              filter: config.bg_blur ? 'blur('+ config.bg_blur + ')' : 'none'
            }"></div>

              <Stepper :config="config" :step-config="config" :data="data" @update="update" />

        </div>
        <div class="footer-notice">
            <div v-for="(notice, key) in config.footer_notice" :key="key">
                <div>{{ notice }}</div>
            </div>
        </div>
        <div class="features-outer">
            <div class="features">
                <div class="features__item">
                    <div class="features__icon"><svg class="svg-icon" viewBox="0 0 24 24"><path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z"></path></svg></div> Kostenlos &amp; unverbindlich
                </div>
                <div class="features__item">
                    <div class="features__icon"><svg class="svg-icon" viewBox="0 0 24 24"><path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z"></path></svg></div> 100% Kundenzufriedenheit
                </div>
                <div class="features__item">
                    <div class="features__icon"><svg class="svg-icon" viewBox="0 0 24 24"><path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"></path></svg></div> Ihre Daten sind sicher
                </div>
            </div>
        </div>
        <div class="footer">

            <div class="footer__inner">
                <div>
                    <h4>{{domain.toUpperCase()}}</h4>
                    <div class="footer__links">
                        <a target="_blank" rel="noopener" href="https://einfach-zum-angebot.de/datenschutzerklaerung/">Datenschutz</a>
                        <a target="_blank" rel="noopener" href="https://einfach-zum-angebot.de/partner-werden/">Partner werden</a>
                        <a target="_blank" rel="noopener" href="https://einfach-zum-angebot.de/impressum/">Impressum</a>
                    </div>
                </div>
                <div>
                    <h4>KONTAKT</h4>
                    <div class="noch-fragen">
                        <h5>Haben Sie Fragen?</h5>
                        <span>0800 001 00 77</span>
                        <div>
                            <a href="tel:08005053001" class="btn">Kostenlos anrufen</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer__copyright">
                © {{firstYearWorking}} - {{currentYear}} {{domain}} - Alle Rechte vorbehalten
            </div>
        </div>
    </div>
</template>

<script>
    import Stepper from './../Stepper.vue'
    import DebugWindow from './../debug/DebugWindow.vue'


    export default {
        name: "SimpleLayout",
        components: {
            Stepper,
            DebugWindow
        },
        data: function(){
            return {
              firstYearWorking: 2018,
              currentYear: 2000,
              domain: '',
              data: {},
            }
        },
        methods: {
          update(val){
            console.log('update value', val);
            for(let k in val){
              this.data[k] = val[k];
            }
          },
          decodePunycodeDomain(hostname) {
            /* PG 2023-11-17:
             * So there are Special Characters in Domains, like ä, ö, ü, ß, etc.
             * These are encoded in punycode, which is a way to encode unicode characters in ASCII.
             * They look like this in the URL: xn--einfach-zur-wrmepumpe-i2b.de
             *
             * Decode URI didn't work for punycode domains, we only have one at the time of writing this comment.
             * This is a workaround for this specific domain. I didn't want to implement a full punycode decoder.
             * I don't think we will need a full punycode decoder in the future, but if we do, we should use a library.
             * Should suffice for the foreseeable future. It should also be compatible with all major browsers.
             *
             * If you find a better way to do this, please do so Tech Wizard.
             * Comment is also longer than the code.
            */
            const punycodeMapping = {
              'xn--einfach-zur-wrmepumpe-i2b.de': 'einfach-zur-wärmepumpe.de',
            };

            return punycodeMapping[hostname] || hostname;
          }
        },
        mounted() {
            this.domain = this.decodePunycodeDomain(window.location.hostname.replace('www.', ''));
            this.currentYear = (new Date()).getFullYear();
        },
        props: ['config']
    }
</script>

<style>

  .c-form--bg-is-dark .text-on-bg-image {
      color: white;
      text-shadow: 0px 0px 15px #000000b3;
  }

    .header {
        background: var(--header-bg-color);
        box-shadow: 0 -5px 20px 0px rgba(0,0,0,.8);
        height: 70px;
        padding: 15px 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
    }

    .header__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

    }

    .footer__inner,
    .header__inner {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }

    .header--center {
      justify-content: center;
    }

    .header__logo {
        height: 100%;
    }

    .header__count-notice {
        color: var(--header-count-notice-color);
        font-size: 15px;
    }

    .header-notice {
        background: var(--header-notice-bg-color);
        color: var(--header-notice-color);

        box-sizing: border-box;
        text-align: center;

        font-size: 22px;
        box-shadow: inset 0 10px 20px -20px rgb(0 0 0 / 80%);
        padding: 1.2em 1em;
    }

    .footer-notice {
        background: var(--footer-notice-bg-color);
        color: var(--footer-notice-color);
        font-size: 15px;
        text-align: center;
        padding: 15px;
        background: white;
        line-height: 1em;
    }

    .footer-notice > div {
        margin-top: 1em;
    }
    .footer-notice > div:first-child{
        margin-top: 0;
    }

    .c-form__titles {
        text-align: center;
        font-weight: bold;

    }

    .c-form__titles h1 {
        font-size: 40px;
    }

    .c-form__titles h2 {
        font-size: 25px;
    }

    .c-form {
        position: relative;
        min-height: 700px;
        padding: 50px 15px;
    }

    .c-form__bg {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
        background-position: center;
        background-size: cover;
    }

    .c-form__bg::after {
      content: ' ';
      display: block;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
      background: v-bind('config.bg_overlay_color ? config.bg_overlay_color : "transparent"');
      opacity: v-bind('config.bg_overlay_opacity ? config.bg_overlay_opacity : "0"');
    }


    .features {
        display: flex;
        justify-content: center;
        font-size: 14px;
        background: var(--features-bg-color);
        color: var(--features-color);
      flex-wrap: wrap;
    }

    .features__item {
        display: flex;
        margin: 15px;
        align-items: center;
    }

    .features__icon {
        display: inline-block;
        width: 2em;
        height: 2em;
        border: 1px solid;
        border-radius: 50%;
        margin-right: 10px;
    }

    .features__icon .svg-icon {
        margin: 3px;
        fill: currentColor;
    }

    .footer {
        color: var(--footer-color);
        background: var(--footer-bg-color);
        padding: 50px;
        padding-bottom: 0;
        font-size: 14px;
    }

    .footer a {
        color: var(--footer-color);
        text-decoration: none;
    }

    .footer h4 {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: bold;
    }

    .footer__links {
        display: flex;
        flex-wrap: wrap;
    }

    .footer__links a {
        font-size: 15px;
        width: 50%;
        margin: 0;
        margin-bottom: 15px;
    }

    .footer__inner {
        display: flex;
        text-align: left;
        justify-content: space-between;
      flex-wrap: wrap;
    }

    .footer__inner .noch-fragen h5 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: normal;
    }

    .footer__inner .noch-fragen span {
        font-size: 25px;
        margin-bottom: 20px;
        display: block;
    }

    .footer__inner .noch-fragen .btn {
        background: rgba(212, 220, 224, 0.37);
        border-radius: 5px;
        padding: 12px;
        color: white;
        font-weight: bold;
        font-size: 11px;
    }

    .footer__copyright {
        font-size: 11px;
        margin-top: 50px;
        padding: 20px;
        border-top: 1px solid rgba(212, 220, 224, 0.37);
        text-align: center;
    }


    .c-form__header {
        text-align: center;
    }
    .c-form__header h3 {
        background: white;
        display: inline-block;
        font-size: 21px;
        padding: 10px 20px;
    }

    .header__right {
      display: flex;
      flex-direction: row;
      font-size: 15px;
      color: #444444;
    }
    .lead-counter {
      text-align: right;
    }

    .header__right > div {
      margin-left: 20px;
    }

    .lead-counter span {
      font-size: 19px;
      font-weight: bold;
      color: #599900;
    }

    @media (max-width: 720px){
      .header__right {
        display: none;
      }
      .header__inner {
        justify-content: center;
      }

      .header-notice {
        font-size: 18px;
      }
    }

  @media (max-width: 680px){
    .features {

    }
  }

</style>
