import { createApp } from 'vue'
import App from './App.vue'
import smoothscroll from 'smoothscroll-polyfill';

createApp(App)
.mount('#app')



// kick off the polyfill!
smoothscroll.polyfill();

export const loadedGoogleMapsAPI = new Promise( (resolve,reject) => {

    window['GoogleMapsInit'] = resolve;

    let GMap = document.createElement('script');

    GMap.setAttribute('src',
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyCV8p-i-2hiFCo-tuX27zRHqT1pa2AKhPg&libraries=places&callback=initMap&channel=GMPSB_addressselection_v1_cABC&callback=GoogleMapsInit&region=DE`);

    document.body.appendChild(GMap);
});
