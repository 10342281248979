<template>
  <div>
    <div class="debug-info" v-if="config?.keyword_box?.debug">
      <p>Modal Visible: {{ showModal }}</p>
      <p>Mouse Left: {{ mouseLeft }}</p>
      <p>Back Button Pressed: {{ backButtonPressed }}</p>
      <p>Modal Shown This Load: {{ modalShownThisLoad }}</p>
    </div>
    <Transition name="modal">
      <div v-if="showModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
          <h2>{{ config?.keyword_box?.title || 'Default Titel' }}</h2>
          <p>{{ config?.keyword_box?.text || 'Default Text' }}</p>
          <div v-if="config?.keyword_box?.sodad_id" ref="keywords" :class="`sodad-${config.keyword_box.sodad_id}`" data-placement="custom-placement">
            <!-- KWB BOX will be inserted here -->
          </div>
          <button @click="closeModal">{{ config.closeButtonText || 'Bleiben' }}</button>
          <button @click="leavePage">{{ config.leaveButtonText || 'Verlassen' }}</button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch} from 'vue';

const props = defineProps({
  config: {
    type: Object,
    default: () => ({}),
  },
});

const keywords = ref(null);

const showModal = ref(false);
const mouseLeft = ref(false);
const mouseLeftTimeout = ref(null);
const backButtonPressed = ref(false);
const modalShownThisLoad = ref(false);

const leavePage = () => {
  // Remove event listeners and navigate back immediately
  removeEventListeners();
  window.history.back();
};

const closeModal = () => {
  showModal.value = false;
  mouseLeft.value = false;
  backButtonPressed.value = false;
};

const handleMouseLeave = (event) => {
  if (modalShownThisLoad.value) return;

  if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
    mouseLeft.value = true;
    mouseLeftTimeout.value = setTimeout(() => {
      showModal.value = true;
      modalShownThisLoad.value = true;
    }, 2000);
  }
};

const handleMouseEnter = () => {
  mouseLeft.value = false;
  if (mouseLeftTimeout.value) {
    clearTimeout(mouseLeftTimeout.value);
  }
};

const handlePopState = (event) => {
  if (modalShownThisLoad.value) {
    return;
  }

  event.preventDefault();
  backButtonPressed.value = true;
  showModal.value = true;
  modalShownThisLoad.value = true;
};

const addEventListeners = () => {
  document.documentElement.addEventListener('mouseleave', handleMouseLeave);
  document.documentElement.addEventListener('mouseenter', handleMouseEnter);
  window.addEventListener('popstate', handlePopState);
};

const removeEventListeners = () => {
  document.documentElement.removeEventListener('mouseleave', handleMouseLeave);
  document.documentElement.removeEventListener('mouseenter', handleMouseEnter);
  window.removeEventListener('popstate', handlePopState);
};

const loadKeywords = () => {
  if (!props.config?.keyword_box?.sodad_id) {
    return;
  }
  (window._sodads = window._sodads || []).push(props.config.keyword_box.sodad_id);
}

watch(keywords, (newValue) => {
  if (newValue !== null && props.config?.keyword_box?.sodad_id) {
    loadKeywords();
  }
});

onMounted(() => {
  addEventListeners();
  history.pushState(null, '', window.location.href);
});

onUnmounted(() => {
  removeEventListeners();
  if (mouseLeftTimeout.value) {
    clearTimeout(mouseLeftTimeout.value);
  }
});
</script>

<style scoped>
.debug-info {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  text-align: center;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
